<template>
    <section class="pt-4 pt-lg-5" id="disclaimer_email_policy">
        <div class="container">
            <!-- Title -->
            <div class="row">
                <div class="col-12 text-center">
                    <h1 class="fs-2 mb-0">Disclaimer and Email Policy</h1>
                </div>
            </div>

            <div class="ps-2 ps-md-3">
                <div class="text-center">
                    <img src="../assets/icons/Disclaimer.png" class="imgcol" width="150" />

                    <p>By accessing BidsPortal website, you are agreeing to be bound by the Terms of Use, Privacy Policy and Disclaimer and Email Policy.</p>
                </div>
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <p>
                            This is a legal agreement ("Agreement") between You ("the User") and BidsPortal.Com ("BidsPortal"), for use of BidsPortal's services - which the User agrees to by subscribing to a paid subscription service
                            constituted by the selection of a username and password. USE OF THIS USERNAME AND PASSWORD TO ACCESS BIDSPORTAL’S MEMBER AREA REPRESENTS AN AGREEMENT BETWEEN THE USER AND BIDSPORTAL GOVERNED BY THE TERMS AND
                            CONDITIONS CONTAINED HEREIN.
                        </p>

                        <p class="mb-0">
                            BidsPortal may, from time to time, opt to update these terms and conditions in anticipation of new features or services. In this case, the User will be notified at least thirty (30) days in advance by email or in
                            an alert within the User's dashboard area upon logging in to BidsPortal. Continued use of BidsPortal's service beyond the notice represents the User's acceptance.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">1. SUBSCRIPTION AND PAYMENT</h6>
                        <p>
                            Subscriptions: BidsPortal bills the subscribers using a prorated, recurring payment system for subscriptions that recur on a monthly, semi-annual or annual basis dependent upon the date of the first payment and
                            selected subscription plan upon payment. Prior to forthcoming billing cycle, the payment method is processed at the select time interval. If a renewal payment fails or is expired and not updated, the User will be
                            notified by BidsPortal and access to BidsPortal's services will be suspended until payment is received. BidsPortal may attempt to renew the card at a later date unless the User requests a cancellation.
                        </p>
                        <p>
                            Recurring payments: Unless a subscriber specifies in written or electronic communication to cancel and or not automatically renew, all subscriptions with BidsPortal are term subscriptions that renew at a later
                            date.
                        </p>
                        <p>
                            Monthly subscriptions: All monthly payments are automatic and final. The User is not notified prior to the renewal date. Cancellation will prevent any further charges from occurring, as long as cancellation
                            occurs prior to renewal. Please view the Termination of Services section for more clarity.
                        </p>

                        <p class="mb-0">
                            Semi-annual and annual subscriptions: Semi-annual and annual subscriptions will auto-renew until you cancel the service. Every time before your subscription auto-renews, we will send an email specifying the
                            amount that will be charged to your credit card. Similarly, after each renewal we will send you a receipt via email specifying the amount that has been deducted together with the next renewal date and the next
                            renewal amount.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">2. TERMINATION OF SERVICES</h6>

                        <p>
                            BidsPortal will continue to provide the Services to you (and you will be entitled to utilise the Services) for the term of your subscription. The term of your subscription will automatically renew for a further
                            term on payment of your subscription.
                        </p>
                        <p>
                            BidsPortal may from time to time increase the amount of subscriptions. Any increase will be notified to you at least 30 days before it is implemented and you will be given the opportunity to opt out of continuing
                            your subscription. If you do not opt out, your subscription will be renewed at the increased rate. Where we are authorized to deduct payments using your credit card, Pay Pal or other means, you authorize us to
                            deduct the increased amount of your subscription.
                        </p>
                        <p>
                            BidsPortal may terminate this agreement with immediate effect by giving notice to you if: (a) you are in breach of this agreement and the breach is not capable of being remedied; (b) you are in breach of this
                            agreement and, in the case of a breach which is capable of being remedied, you fail to remedy the breach within 30 days of receipt of written notice of the breach; (c) you fail to pay any amount due to BidsPortal
                            within 21 days of receiving a written demand for payment; (d) you are subject to any application for winding up or liquidation, or for the appointment of a liquidator, receiver or manager, or are subject to any
                            other form of insolvency event; or (e) BidsPortal ceases to provide the Services.
                        </p>
                        <p class="mb-0">
                            If this agreement expires or is terminated, you must immediately stop using the Services. On termination, all payments due to BidsPortal prior to termination must be made within 21 days.
                        </p>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">3. WARRANTIES</h6>
                        <strong>BidsPortal warrants that:</strong>
                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item d-flex text-body mb-0"><i class="fa-solid fa-check text-warning me-2"></i>The Services will substantially conform to the description of the Services specified on the Website; and</li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>The features and functionality of the Services will not be materially decreased during the period of your registration.
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>BidsPortal does not warrant that the Services will be provided error free or will be available at all times or that the information provided about bidsis
                                true, accurate and up to date.
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Except to the extent that any law or statute prohibits the exclusion of any condition or warranty, all other conditions, guarantees, warranties, rights or
                                remedies are excluded.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="my-4 ps-2 ps-md-3">
                <div class="d-md-flex p-3 bg-light rounded-3">
                    <div class="mt-2 mt-md-0">
                        <h6 class="mb-1">4. EMAIL POLICY</h6>
                        <p>
                            BidsPortal has a strict Anti-spam and Unsolicited Commercial E-mail Policy. In order to ensure high quality communications that you genuinely value, we employ the following double opt-in/opt out and list
                            management standards:
                        </p>
                        <strong>Double opt-in means that:</strong>
                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>You submit your information, including your e-mail address, to us through an on-line form. In order to successfully submit your information you must agree
                                and express consent to receive e-mail alerts from BidsPortal by clicking a checkbox in that on-line form.
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>We send an e-mail message to the address you submitted requesting that you click a link in that message to confirm that the e-mail address is bona fide,
                                belongs to you and that you wish to receive automatic e-mail notifications from us.
                            </li>
                        </ul>
                        <strong>Opt out means that:</strong>
                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>Every notification from BidsPortal provides instructions for deactivating automatic notifications from us. If you deactivate notifications, we still maintain
                                your account information and saved search criteria, which allows you to reactivate automatic e-mail notifications in the future and also to use some other features of BidsPortal. You can log in to BidsPortal
                                at any time to deactivate or reactivate e-mail notifications. After logging into BidsPortal, simply click the "Update your account information including name, organization, e-mail address, password and
                                whether or not you wishto receive e-mail notifications" link, set the "e-mail notification" field to "ON" or "OFF" as desired and then Submit your changes.
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>In order to comply with the strict policies of many major e-mail and network service providers, BidsPortal is obligated to disable accounts with e-mail
                                addresses that result in undeliverable messages or "hard bounces" after messages are sent to those e-mail addresses from BidsPortal. A failure on the part of BidsPortal to comply with these strict policies
                                carries the risk of having all e-mail messages to users being blocked by the service providers.
                            </li>
                            <li class="list-group-item d-flex text-body mb-0">
                                <i class="fa-solid fa-check text-warning me-2"></i>If BidsPortal receives undeliverable notices after sending messages to you, your BidsPortal account may be suspended. Please note that BidsPortal may receive
                                undeliverable notices for a variety of reasons including but not limited to temporary problems with your network or e-mailserver, your inbox is over quota, your e-mail address is no longer active, and/or your
                                e-mail service provider or anti-spam software is blocking messages from BidsPortal.
                            </li>
                        </ul>
                        <p>
                            Once your account has been suspended, BidsPortal will cease sending any further messages to you and you will be unable to log in to BidsPortal. If your account has been suspended, you will need to contact
                            BidsPortal at support@bidsportal.com to have your account reactivated.
                        </p>
                        <p class="mb-0">
                            If you are receiving spam or Unsolicited Commercial email messages that appear to be from BidsPortal, please report it to support@bidsportal.com.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
