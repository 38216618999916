<template>
    <section class="pt-0 pt-lg-5" id="faq">
        <div class="container">
            <!-- Title -->
            <div class="row">
                <div class="col-12 text-center mb-4">
                    <h2 class="mb-0">Frequently Asked Questions</h2>
                </div>
            </div>

            <!-- Accordion START -->
            <div class="row">
                <div class="col-10 mx-auto">
                    <div class="accordion accordion-icon accordion-bg-light" id="accordionExample2">
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-1">
                                <button class="accordion-button fw-bold rounded d-inline-block collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                    1) How does BidsPortal Work?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-1" class="accordion-collapse collapse show" aria-labelledby="heading-1" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        BidsPortal provide both suppliers and service providers with the opportunity to view an extensive collection of business procurements from many different industries including the public and private
                                        sectors. For a nominal subscription, a registered user acquires access to all of our bids including the latest current business procurements.
                                    </p>
                                    <p class="mb-0">
                                        Using our powerful search tool, you can refine your bids search to display all the relevant bids pertaining to your industry. Once you find a bid that you are interested in, you can click on the bid
                                        to view/download the details of this potential business opportunity.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-2">
                                <button class="accordion-button fw-bold rounded d-inline-block collapsed d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                    2) What Services do you Offer?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-2" class="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        BidsPortal is a bid notification service that will help your business to grow. We brings business leads directly to you; business leads that you might otherwise have missed. New bids are listed daily
                                        from all levels of government and private sector companies throughout the world. Bids are dispatched daily to our subscribers to ensure a timely delivery. The bids are sent via email and as a
                                        subscriber, you can access the notifications and other bid information directly from our website.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-3">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                    3) What are the types of bids you provide?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="heading-3" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        BidsPortal allows you to search through thousands of bids within the system. These include Bids, Solicitations, Tenders, RFPs, RFQs, and RFIs etc. Following are a listing of agencies we cover include:
                                    </p>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <table style="margin-right: auto;" class="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <td class="list_type_class">Federal Agencies</td>
                                                    <td class="list_type_class">State &amp; Local Agencies</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">Cities and Countries</td>
                                                    <td class="list_type_class">Heath Care Industry</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">IT &amp; Communication</td>
                                                    <td class="list_type_class">US Military and Defense</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">Construction Authorities</td>
                                                    <td class="list_type_class">Transportation Districts</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">Municipalities</td>
                                                    <td class="list_type_class">Association of Governments</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">State Universities</td>
                                                    <td class="list_type_class">K12 and Higher Education</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">Unified School Districts</td>
                                                    <td class="list_type_class">College Districts</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">Fire Authorities</td>
                                                    <td class="list_type_class">Airport Authorities</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">Water Districts</td>
                                                    <td class="list_type_class">Sanitation Districts</td>
                                                </tr>
                                                <tr>
                                                    <td class="list_type_class">International Governments</td>
                                                    <td class="list_type_class">Private Industries</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-4">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                    4) How will I benefit?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-4" class="accordion-collapse collapse" aria-labelledby="heading-4" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        As a registered subscriber, you will enjoy the convenience of a hi-tech online bids notification service where you can get all the latest procurement and business leads delivered daily to your Inbox.
                                        We realize that you are busy and that you don't have time to search our website. In this case you will only receive bids relevant to your business via email, which will save you time, money, and
                                        valuable resources.
                                    </p>
                                    <p class="mb-0">
                                        BidsPortal provide you with the confidence of not missing out on any potential contract opportunitiy. You are able to target the most promising leads and will receive a steady stream of new business
                                        opportunities leading directly to increased work, revenue, and profits -<strong> We’re your way to power!</strong>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-5">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                                    5) What does it mean to set up an Alert?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-5" class="accordion-collapse collapse" aria-labelledby="heading-5" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        Setting up an ‘Alert’ agent will automatically generate and email a list of bids that matches your Alert criteria. If there is more than one bid that matches your Alert search criteria, information
                                        about all of the bids will appear in the same email. By clicking on the “Bid Title” in the email will take you to the BidsPortal website, where you can see additional information related to that
                                        particular bid.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-6">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                                    6) Can I send a bid notice to a colleague from the website?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-6" class="accordion-collapse collapse" aria-labelledby="heading-6" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        Yes, you can send bid notices to colleagues from the “Bids/Opportunity View” tab - In this tab “tick” the box next to the bids(s) number you wish to send and click on the “Envelope” icon at the top of
                                        the Bids list to enter Colleagues Email Address and a Brief Message.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-7">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                                    7) How do I Subscribe?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-7" class="accordion-collapse collapse" aria-labelledby="heading-7" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        To subscribe, simply click the “Register” tab to create an account with BidsPortal with basic user information – upon registration visit our
                                        <strong style="color: blue;"> “Plans and Pricing” </strong> and select the desired subscription package to checkout. Upon payment confirmation, we’ll activate your account and you get access to the
                                        latest bids and business leads.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-8">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                                    8) What does it cost?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-8" class="accordion-collapse collapse" aria-labelledby="heading-8" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        Subscription to the comprehensive BidsPortal leads service is either by monthly, semi-annual or annual payment and includes profiling, research, account management, daily bid searches, email of bid
                                        notices and day-to-day support. For your investment in BidsPortal, you will have access to all the opportunities you need for your business to thrive.
                                    </p>
                                    <p class="mb-0">
                                        Visit our<span style="color: blue;"> “Plans and Pricing” </span>page for more information. Kindly note: That our pricing is applicable to a single user license. Should you wish to acquire more
                                        licenses for logins and additional emails, please enquire with our Sales Team <span style="color: blue;">(support@bidsportal.com)</span> on our available corporate and enterprise licenses.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-9">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-9" aria-expanded="false" aria-controls="collapse-9">
                                    9) What are my payment options?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-9" class="accordion-collapse collapse" aria-labelledby="heading-9" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>We have tailored our subscription packages to suit your budget. This allows you to subscribe on a monthly basis or by annual subscription.</p>
                                    <p class="mb-0">
                                        Payment is received by:<br />
                                        Credit Card Payment via PayPal (We will debit your credit card account on a Monthly / Quarterly / Annual basis – depends on the plan subscribed for)
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-10">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-10" aria-expanded="false" aria-controls="collapse-10">
                                    10) How many people can Log-In with a single account?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-10" class="accordion-collapse collapse" aria-labelledby="heading-10" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>BidsPortal account access is limited to a single user. If multiple logins are observed, you account will be suspended.</p>
                                </div>
                            </div>
                        </div>
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-11">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-11" aria-expanded="false" aria-controls="collapse-11">
                                    11) How can I unsubscribe or cancel my subscription?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-11" class="accordion-collapse collapse" aria-labelledby="heading-11" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        Annual, semi-annual and monthly plans have the option to automatically renew to lock in pricing long term. You can cancel your subscription at any time from your PayPal account. There is no minimum
                                        required duration or contract. You are free to cancel your subscription at any time.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-12">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-12" aria-expanded="false" aria-controls="collapse-12">
                                    12) Can I cancel my service when I want?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-12" class="accordion-collapse collapse" aria-labelledby="heading-12" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        Yes, of course. If you are thinking of cancelling your subscription to BidsPortal please contact us as soon as possible. The earlier you let us know about anything you are dissatisfied with, the more
                                        likely that we will be able to do something to rectify the problem for you.
                                    </p>
                                    <p class="mb-0">
                                        Our recurring plans may be canceled at any time to cease further billing to an account. Annual, Quarterly and Monthly plans automatically renew, but the renewal may be canceled prior to the renewal
                                        date to avoid additional charges.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Item -->
                        <div class="accordion-item mb-3">
                            <h6 class="accordion-header font-base" id="heading-13">
                                <button class="accordion-button fw-bold collapsed rounded d-block pe-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-13" aria-expanded="false" aria-controls="collapse-13">
                                    13) I forgot my Password?
                                </button>
                            </h6>
                            <!-- Body -->
                            <div id="collapse-13" class="accordion-collapse collapse" aria-labelledby="heading-13" data-bs-parent="#accordionExample2">
                                <div class="accordion-body mt-3">
                                    <p>
                                        If you forgot your password, click on “Forgot Password” link and follow the instructions. If you have any difficulties, or if your email address has changed, please contact our support team at
                                        (913) 232-2255.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Accordion END -->
        </div>
    </section>
</template>
