<template>
    <section class="pt-0 pb-0" id="about_us">
       
        <div class="container-fluid" style="background-image: url(assets/images/about-us.jpg); background-position: center left; background-size: cover;">
            <div class="row justify-content-between pt-0 pb-5">
				

               
                <div class="col-md-12 pb-5 mb-0 mb-lg-5 pt-7 pb-9" style="text-align:right;font-size:30px;letter-spacing:3px">
                    <h2 class="text-white white1"><i>Everything you need for winning more Business</i></h2>
                    <p class="lead text-white mb-0"><i>-One Place, Lowest pricing, Unlimited Alerts and Searching</i></p>
                </div>
            </div>
         </div>

        <div class="container pt-4 pt-md-5">
            <div class="row g-4 align-items-center">
              <div class="col-lg-7">
                    <!-- Title -->
                    <h3 class="mb-4">Who We Are<span class="text-primary"></span></h3>
                    <!-- Info -->
                    <p>
                        BidsPortal is your leading edge partner for purchasing organizations and suppliers to provide a highly effective e-procurement platform providing a great, reliable procurement search/posting and proposal support
                        facility without breaking the bank. We are renowned for being a global, comprehensive service that will continue to evolve rapidly to meet the forever growing needs of buyers and sellers throughout United States of
                        America and across the globe.
                    </p>
                    <p>
                        Our service is excellent and our procurement alert facility is one of the best in industry. Our website feature bids, solicitations, combined synopsis, tenders, RFPs, RFQs, RFIs, Pre-Solicitations, Sources Sought
                        Notices and many other types of Procurement Solicitations in order to streamline the procurement process, reduce costs to taxpayers, improve the quality of supplier's services and help stimulate local, national and
                        international economies.
                    </p>
                    <p class="mb-4">We are constantly fine-tuning every aspect of our service based on the great feedback we receive from our awesome subscribers.</p>
                </div>
                <!-- Image -->
                <div class="col-lg-5 text-center">
                    <img src="../assets/icons/who-we-are.png" alt="" />
                </div>
            </div>
        </div>

        <div class="container-fluid bg-success bg-opacity-10 pt-0 pt-md-5">
            <div class="row mb-4">
                <div class="col-12 text-center">
                    <h3>Our Key Differentiators</h3>
                    <p class="mb-0">Deliver Opportunities! Facilitate Growth! Connect Buyers & Suppliers.</p>
                    <p class="mb-0">BidsPortal is the easiest and most dependable platform to find the right bids and business opportunities for your company.</p>
                </div>
            </div>
            <div class="row pb-5 g-4 g-lg-5 justify-content-center">
                <!-- Step item -->
                <div class="col-sm-6 col-md-3 text-center position-relative">
                    <!-- SVG decoration -->

                    <div class="px-4">
                        <!-- Image -->
                        <img
                            src="../assets/icons/Pricing.png"
                            class="w-100px mb-3"
                            alt=""
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="We let you download a single bid for as little as $5/-  With monthly, semi-annual or annual subscription get complete access to BidsPortal platform with easy options to pay by Credit Card , PayPal or EFT"
                        />
                        <!-- Title -->
                        <h6>Competitive Pricing</h6>
                    </div>
                </div>

                <!-- Step item -->
                <div class="col-sm-6 col-md-3 position-relative text-center">
                    <!-- SVG decoration -->

                    <div class="px-4">
                        <!-- Image -->
                        <img
                            src="../assets/icons/Transparency.png"
                            class="w-100px mb-3"
                            alt=""
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Your gateway to the global procurement market, we provide information from beginning to the end of procurement process."
                        />

                        <!-- Title -->
                        <h6>Information Transparency</h6>
                    </div>
                </div>

                <!-- Step item -->
                <div class="col-sm-6 col-md-3 text-center">
                    <div class="px-4">
                        <!-- Image -->
                        <img
                            src="../assets/icons/TimeSource.png"
                            class="w-100px mb-3"
                            alt=""
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Early Access to contract opportunities from nationl and international sources- most comprehensive source of information for purchasers and suppliers of good and services. "
                        />

                        <!-- Title -->
                        <h6>Timely and Reliable Source</h6>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 text-center">
                    <div class="px-4">
                        <!-- Image -->
                        <img
                            src="../assets/icons/Phone-Support.png"
                            class="w-100px mb-3"
                            alt=""
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="We offer a great personal customer service interface and all client communications are handled by staff who are expert in the field of procurement. Our team is genuinely committed to help grow your business"
                        />

                        <!-- Title -->
                        <h6>Customer Support</h6>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pt-4 pt-md-5">
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-3  text-center">We Help Grow your Business</h3>
                    <div class="rounded-3 position-relative overflow-hidden p-4 p-xl-5">
                        <div class="row g-4 align-items-center">
                            <!-- Image -->
                            <div class="col-lg-6 fade-right">
                                <img src="../assets/icons/grow1.png" alt="" style="display: block; max-width: 100%; height: 400px;" />
                            </div>
                            <!-- Content -->
                            <div class="col-lg-6">
                                <!-- Title -->
                                <!-- <h2 class="mb-3 fs-2">We Help Grow your Business</h2> -->
                                <p>With tight budgets and limited resources, it can be a challenge for your business also to find the time needed to source procurement opportunities that could help your business grow.</p>
                                <p>
                                    Our tailored service combined with access to the United States and Globally largest database of procurement opportunities, it’s likely you don’t have to spend countless hours trying to identify the most
                                    important business opportunities. It’s essential that you find a solution that takes the hard work out of finding contract opportunities and gives you everything you need to win them – that’s where
                                    BidsPortal can help.
                                </p>
                                <p>Our unique subscription model is designed specifically so that you only pay for what you need – with BidsPortal it takes just a few steps to start winning new business opportunities today.</p>
                                <p class="mb-3">Put your time and money to better use and let the opportunities come to you.</p>
                            </div>
                        </div>
                        <!-- Row END -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Background image -->
        <div class="mt-2 mb-0 p-3 p-sm-5" style="background-image: url(assets/images/Chess-Image.jpg); background-position: center center; background-repeat: no-repeat; background-size: cover;">
            <!-- Banner title -->
            <div class="row">
                <div class="col-md-10 mx-auto text-center">
                    <h3 class="text-dark display-10 my-5">Driving Force for Business Success</h3>
                    <h5>While you're busy running your business, we'll help you grow it</h5>
                </div>
            </div>

            <!-- Booking from START -->
            <!-- <form class="bg-mode position-relative px-3 px-sm-4 pt-4 mb-4 mb-sm-0"> -->

            <div class="row g-4 position-relative">
                <!-- Card item -->
                <div class="col-sm-6 col-lg-4 mb-4 col-xl-4 text-center">
                    <div class="card h-100 p-4 white-bg">
                        <!-- Image -->
                        <div class="px-4">
                            <img src="../assets/icons/Win-contracts.png" class="w-85px mb-1" alt="" />
                        </div>
                        <!-- Card body START -->
                        <div class="card-body mt-2 p-0">
                            <h5 class="mb-3 mt-3">Win More Contract</h5>
                            <!-- <h6 class="fw-normal">Access to the most comprehensive coverage of government and private opportunities covering all industrial fields without any additional fee.</h6> -->
                            <p class="fw-normal">Access to the most comprehensive coverage of government and private opportunities covering all industrial fields without any additional fee.</p>
                        </div>
                        <!-- Card body END -->
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 mb-4 col-xl-4 text-center">
                    <div class="card white-bg h-100 p-4">
                        <!-- Image -->
                        <div class="px-4">
                            <img src="../assets/icons/bid-participation.png" class="w-85px mb-1" alt="" />
                        </div>
                        <!-- Card body START -->
                        <div class="card-body mt-2 p-0">
                            <h5 class="mb-3 mt-3">Bid Participation</h5>
                            <!-- <h6 class="fw-normal">Access to the most comprehensive coverage of government and private opportunities covering all industrial fields without any additional fee.</h6> -->
                            <p class="fw-normal">Participating in a bid is just one click away! Each bid opportunity includes a link that will take you directly to the bid within the agency’s vendor portal.</p>
                        </div>
                        <!-- Card body END -->
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 mb-4 col-xl-4 text-center">
                    <div class="card white-bg h-100 p-4">
                        <!-- Image -->
                        <div class="px-4">
                            <img src="../assets/icons/fastandeasy.png" class="w-85px mb-1" alt="" />
                        </div>
                        <!-- Card body START -->
                        <div class="card-body mt-2 p-0">
                            <h5 class="mb-3 mt-3">Fast and Easy Access</h5>
                            <!-- <h6 class="fw-normal">Access to the most comprehensive coverage of government and private opportunities covering all industrial fields without any additional fee.</h6> -->
                            <p class="fw-normal">The #1 source for all Federal, State and Local bids. Our intuitive user interface minimizes the time it takes to search for bids and participate in projects</p>
                        </div>
                        <!-- Card body END -->
                    </div>
                </div>
            </div>
            <!-- </form> -->
            <!-- Booking from END -->
        </div>
    </section>
</template>

<style scoped>
    .white-bg {
        background-color: #ffffffbf;
    }
	.white1{
		font-size: 30px;
		font-weight: 500;
	}
</style>
